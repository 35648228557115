import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import media from '../utils/media';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
  }

  :root {
    font-size: 10px;
  }

  body {
    font-family: Roboto;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: rgba(0, 0, 0, 0.8);
    min-height: 100vh;
    position: relative;
    font-size: 1.6rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: Roboto, sans-serif;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 1.6rem;
  }
  
  code {
    word-break: break-word;
  }

  code * {
    font-family: Source Code Pro !important;
  }

  pre code {
    word-break: normal;
    font-size: 14px !important;
  }

  :not(pre) > code[class*="language-"], pre[class*="language-text"] {
    background-color: #E8E8E8;
    font-family: Source Code Pro;
    color: #EC6969;
    font-size: small;
    font-weight: 400;
    padding: 1px 4px;
  }

  footer {
    font-weight: 400;
    font-style: normal;
  }

  footer > a, a:link, a:visited {
    text-decoration: none;
    color: #000000CC;
  }
`;

const Footer = styled.footer`
  display: block;
  height: 4rem;
  bottom: 0;
  text-align: center;
  left: 0;
  position: absolute;
  width: 100%;
  font-size: small;
`;

const Content = styled.div`
  width: 60%;
  max-width: 728px;
  margin: 0 auto;
  padding-bottom: 4rem;

  ${media.tablet`
    width: 80%;
  `}
`;

class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header title={data.site.siteMetadata.title} />
            <Content>{children}</Content>
            <Footer>
              ©
              <a
                href="https://github.com/jwlee1108"
                target="_blank"
                rel="noopener noreferrer"
              >
                jwlee
              </a>
              &nbsp;Built with&nbsp;
              <a
                href="https://github.com/agneym/gatsby-blog-starter"
                target="_blank"
                rel="noopener noreferrer"
              >
                gatsby-minimal-blog
              </a>
            </Footer>
            <GlobalStyles />
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
